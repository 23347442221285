<template>
  <validation-observer v-slot="{ invalid }">
    <div class="card field">
      <div class="card-header">
        <p class="card-header-title">Negotiator Pay Less System Error</p>
      </div>
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              :rules="negotiatorRuleObj"
            >
              <div class="control">
                <label class="label">Negotiator System Error Title*</label>
                <input
                  type="text"
                  class="input"
                  v-model="ic.systemErrorTitle"
                  name="System Error Title"
                />
              </div>
              <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
            </validation-provider>
            <validation-provider
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
              :rules="negotiatorRuleObj"
            >
              <div class="control">
                <label class="label">Negotiator System Error Content*</label>
                <input
                  type="text"
                  class="input"
                  v-model="ic.systemErrorContent"
                  name="System Error Content"
                />
              </div>
              <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
            </validation-provider>
          </div>
          <div class="column">
            <img
              src="@/assets/pay_less_error_guide.png"
              alt="Pay Less Error Guide"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <p class="content"><em>* indicates required field</em></p>
    </div>
    <div class="field">
      <div class="buttons">
        <button :disabled="invalid" class="button is-success" @click="save">
          Save
        </button>
        <button :disabled="invalid" class="button is-info" @click="saveNext">
          Save and Next
        </button>
        <button class="button is-light" @click="revert">
          Revert
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import saveRevert from "@/mixins/negotiatorMixins";
export default {
  mixins: [saveRevert],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      ic: {
        systemErrorTitle: null,
        systemErrorContent: null
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalInstanceConfig(vm.$route.params.id);
        await vm.fetchInstances();
        vm.loadView.call(vm);
      } catch (e) {
        vm.$store.commit(
          "SHOW_NOTIFICATION",
          `Error loading InstanceNegotiator; ${e}`
        );
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$store.commit("SHOW_PROGRESS");
    this.$store.commit("HIDE_NOTIFICATION");
    try {
      await this.fetchPaymentPortalInstanceConfig(to.params.id);
      this.loadView();
    } catch (e) {
      this.$store.commit(
        "SHOW_NOTIFICATION",
        `Error loading instance id=${to.params.id}; ${e}`
      );
    } finally {
      this.$store.commit("HIDE_PROGRESS");
      next();
    }
  },
  methods: {
    loadView() {
      this.ic.instanceId = this.$route.params.id;
      this.ic.systemErrorContent = this.$store.state.instanceConfig.systemErrorContent;
      this.ic.systemErrorTitle = this.$store.state.instanceConfig.systemErrorTitle;
    },
    async saveNext() {
      await this.save();
      this.$router.push({ name: "overTime" });
    }
  }
};
</script>
