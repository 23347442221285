var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"card field"},[_c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Negotiator Pay Less System Error")])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":_vm.negotiatorRuleObj},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Negotiator System Error Title*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.systemErrorTitle),expression:"ic.systemErrorTitle"}],staticClass:"input",attrs:{"type":"text","name":"System Error Title"},domProps:{"value":(_vm.ic.systemErrorTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "systemErrorTitle", $event.target.value)}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":_vm.negotiatorRuleObj},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Negotiator System Error Content*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.systemErrorContent),expression:"ic.systemErrorContent"}],staticClass:"input",attrs:{"type":"text","name":"System Error Content"},domProps:{"value":(_vm.ic.systemErrorContent)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "systemErrorContent", $event.target.value)}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('img',{attrs:{"src":require("@/assets/pay_less_error_guide.png"),"alt":"Pay Less Error Guide"}})])])])]),_c('div',{staticClass:"field"},[_c('p',{staticClass:"content"},[_c('em',[_vm._v("* indicates required field")])])]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('button',{staticClass:"button is-info",attrs:{"disabled":invalid},on:{"click":_vm.saveNext}},[_vm._v(" Save and Next ")]),_c('button',{staticClass:"button is-light",on:{"click":_vm.revert}},[_vm._v(" Revert ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }